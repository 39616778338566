@charset "UTF-8";
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: "Open Sans";
  src: url("https://design-system.cdn.yle.fi/OpenSans-Regular.woff2") format("woff2"), url("https://design-system.cdn.yle.fi/OpenSans-Regular.woff") format("woff");
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: "Open Sans";
  src: url("https://design-system.cdn.yle.fi/OpenSans-Bold.woff2") format("woff2"), url("https://design-system.cdn.yle.fi/OpenSans-Bold.woff") format("woff");
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: "Open Sans";
  src: url("https://design-system.cdn.yle.fi/OpenSans-ExtraBold.woff2") format("woff2"), url("https://design-system.cdn.yle.fi/OpenSans-ExtraBold.woff") format("woff");
  font-style: normal;
  font-weight: 800;
}
html {
  background: linear-gradient(180deg, #fff 77.76%, #f8f9fb 100%);
  min-height: 100vh;
}

body {
  color: black;
  font-size: 16px;
  line-height: 1.5;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  background: url(../images/bg-brand-wide.png) no-repeat;
  background-size: 2114px;
  background-position: center top;
  min-height: 100vh;
}

#yle-app-logo {
  margin: 24px 0 32px 0;
  height: 80px;
  width: 80px;
  background: url(../images/yle-app-logo.png) no-repeat;
  background-size: 80px;
}

h1,
h2 {
  font-weight: 800;
  line-height: 1.3;
  margin: 0;
}

h1 {
  font-size: 32px;
  margin-bottom: 16px;
}

h2 {
  font-size: 24px;
}

p.benefits-sv {
  margin-bottom: 16px;
}

ul#benefits {
  margin-bottom: 20px;
}
ul#benefits li {
  display: inline;
}
ul#benefits li:not(:first-child):before {
  content: "•";
  margin: 0 0.3em;
}

#header {
  text-align: center;
  background: #fff;
  padding: 0;
  margin: 0;
  height: 50px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
}
#header .logo {
  display: inline-block;
  height: 30px;
  width: 30px;
  margin-top: 10px;
}

#hero-wrapper {
  width: 100%;
}
#hero-wrapper #hero {
  min-height: 542px;
  max-width: 800px;
  margin: 0 auto;
  padding: 48px 20px 0 20px;
  background: url(../images/phone_2x.webp) no-repeat;
  background-size: 466px;
  background-position: top 40px right -20px;
}
#hero-wrapper #hero #text {
  width: 356px;
}

#store-container {
  max-width: 740px;
  margin: 48px auto 48px auto;
}
#store-container ul li {
  display: block;
  margin: 16px 0;
}
#store-container ul li.qr-code {
  height: 80px;
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: center;
}
#store-container ul li.qr-code p {
  font-weight: 600;
  font-size: 20px;
}
#store-container ul li.store-links {
  height: 48px;
  background-size: 100% 100%;
  background-position: center center;
  background-repeat: no-repeat;
  display: flex;
  gap: 16px;
  justify-content: space-between;
}
#store-container ul li.store-links a {
  display: block;
  height: 48px;
  width: 173px;
}
#store-container ul li.or {
  font-weight: 900;
  font-size: 18px;
  text-transform: uppercase;
  padding: 16px 0;
  display: flex;
  flex-basis: 100%;
  align-items: center;
  margin: 8px 0;
}
#store-container ul li.or span {
  padding: 0 16px;
}
#store-container ul li.or:before, #store-container ul li.or:after {
  content: "";
  flex-grow: 1;
  background: #d8d9db;
  height: 2px;
  font-size: 0;
  line-height: 0;
}

#phone {
  display: none;
}

@media screen and (max-width: 750px) {
  #yle-app-logo {
    display: none;
  }

  #phone {
    display: block;
    height: 300px;
    background: url(../images/phone_2x.webp) top center no-repeat;
    background-size: 300px;
  }

  h1 {
    margin-top: 8px;
    text-align: center;
  }

  #language-sv #benefits {
    text-align: left;
  }

  #hero-wrapper #hero {
    padding: 16px 0 16px 0;
    background-position: top 140px center;
    background-size: 400px 400px;
    background: none;
    width: 100%;
  }
  #hero-wrapper #hero #text {
    display: flex;
    flex-direction: column;
    align-content: center;
    padding: 0 16px;
    width: 394px;
    margin-left: auto;
    margin-right: auto;
  }

  #store-container {
    display: inline-block;
    margin-top: 0;
    margin-bottom: 32px;
  }
}
body.desktop .hide-on-desktop,
body.mobile .hide-on-mobile,
body.android .hide-on-android,
body.ios .hide-on-ios {
  display: none !important;
  /* Hidden on desktop */
}

#naurumaraton {
  background: linear-gradient(white, #f9f9f9);
}
#naurumaraton #yle-app-logo {
  margin-left: auto;
  margin-right: auto;
}
#naurumaraton h1 {
  font-size: 26px;
  text-align: center;
}
#naurumaraton h2 {
  font-weight: 600;
  font-size: 18px;
  text-align: center;
}
#naurumaraton .naurumaraton-cta-mobile {
  margin: 24px 16px 0 16px;
  text-align: center;
}
#naurumaraton .button {
  background-color: black;
  color: white;
  padding: 16px 24px;
  font-weight: 600;
  border-radius: 999px;
  text-decoration: none;
  display: inline-block;
}
#naurumaraton #wrap {
  background: url(../images/bg-brand-wide.png) no-repeat;
  background-size: 2114px;
  background-position: center top;
}
#naurumaraton #hero-wrapper {
  background: none;
}
#naurumaraton #hero-wrapper #hero {
  padding-top: 48px;
  padding-left: 0;
  width: 700px;
  height: 100vh;
  min-height: auto;
  background: url(../images/naurumaraton_phone_desktop.png) no-repeat;
  background-size: 325px;
  background-position: top 24px right;
}
#naurumaraton #hero-wrapper #hero #text {
  width: 350px;
}
#naurumaraton .naurumaraton-cta-desktop {
  margin-top: 40px;
  padding: 0 16px;
  text-align: center;
}
#naurumaraton .naurumaraton-cta-desktop h2 {
  margin-bottom: 16px;
}
#naurumaraton .naurumaraton-cta-desktop .install-app-container {
  margin-top: 8px;
  display: inline-flex;
  flex-direction: column;
  align-items: stretch;
  gap: 16px;
}
#naurumaraton .naurumaraton-cta-desktop .install-app {
  border: solid 2px #00b4c8;
  padding: 20px;
  background: white;
  border-radius: 24px;
  font-weight: 600;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
#naurumaraton .naurumaraton-cta-desktop .install-app img {
  margin-left: 16px;
}
#naurumaraton .naurumaraton-cta-desktop .install-app a {
  color: black;
}
#naurumaraton .naurumaraton-cta-desktop .install-app-divider {
  font-size: 20px;
  font-weight: 600;
  flex: 0 1 auto;
  text-align: center;
}
#naurumaraton .naurumaraton-cta-desktop .install-app-link {
  flex-direction: column;
  gap: 0;
}
#naurumaraton .naurumaraton-footer {
  padding-top: 32px;
  text-align: center;
}
@media screen and (max-width: 700px) {
  #naurumaraton .naurumaraton-cta-mobile {
    text-align: center;
  }
  #naurumaraton #hero-wrapper #hero {
    width: 100%;
    padding-top: 0;
    background-size: 300px;
    background-position: top 40px right 0;
    min-height: auto;
  }
  #naurumaraton #header {
    display: none;
  }
  #naurumaraton h1 {
    font-size: 24px;
  }
  #naurumaraton #hero-wrapper #hero {
    background: none;
    padding-top: 16px;
  }
  #naurumaraton #phone {
    background: url(../images/naurumaraton-mockups.png) top center no-repeat;
    background-size: 300px;
    height: 320px;
  }
}
